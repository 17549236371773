import styled from 'styled-components';

export default styled.a`
    background-color: transparent;
    color: ${({ theme }) => theme.white};
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8125rem;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 0.6875rem 2rem;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    border: solid 2px ${({ theme }) => theme.white};
    &:hover {
        background-color: ${({ theme }) => theme.blue};
        color: ${({ theme }) => theme.white};
    }
`;
