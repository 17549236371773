import { createGlobalStyle } from 'styled-components';
import 'typeface-open-sans';
import 'typeface-montserrat';

const defaultTheme = {
    blue: '#5289ff',
    blueDark: '#33559e',
    gray: '#f2f2f2',
    grayDark: '#e9e9e9',
    grayDarker: '#a9a8a2',
    orange: '#f47e20',
    white: '#fff',
    whiteDark: '#fafafa',
    text: '#535249',
    tag: '#706F67',
    yellowDark: '#535249',
    headlinesColor: '#333333',
    shadowColor: '#dcdcdc',
};

export default defaultTheme;

export const GlobalStyle = createGlobalStyle`
    html {
        font-size: 16px;
    }

    body {
        color: ${props => props.theme.text};
        background-color: ${props => props.theme.gray};
        font-family: 'Open Sans', sans-serif;
        margin: 0;
        padding: 0;
        line-height: 24px;
    }

    * {
        min-height: 0;
        min-width: 0;
    }

    pre, code {
        font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
        text-shadow: none!important;
    }

    pre {
        font-size: 0.8em;
    }
`;
