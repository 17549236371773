import styled from 'styled-components';

export default styled.a`
    display: flex;
    align-items: center;
    color: ${({ color }) => color};
    cursor: pointer;
    font-weight: bold;
    font-size: 0.8125rem;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    border: none;
    background: none;
`;
