import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Link as RouterLink } from '@reach/router';
import { translate } from 'react-polyglot';

export const BaseLink = styled(
    // HACK: storybook don't like gatsby links but we need them for code splitting
    process.env.STORYBOOK_GIT_BRANCH ? RouterLink : Link,
)`
    color: ${({ inverted, theme }) => (inverted ? theme.white : theme.text)};
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 0.8rem;
    transition: font-weight 0.3s ease-out;
    box-sizing: border-box;

    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};

    @media (min-width: 600px) {
        line-height: 5rem;
        height: 5rem;
        border-bottom: ${({ active, inverted, theme }) =>
            active
                ? `5px solid ${inverted ? 'transparent' : theme.blue}`
                : 'border-bottom: none'};
    }
    @media (max-width: 600px) {
        height: auto;
        transition: font-weight 0.3s ease-out, opacity 0.3s ease-out 0.2s;
        opacity: ${({ open }) => (open ? 1 : 0)};
        font-size: 1.1rem;
        display: block;
        text-align: center;
    }
`;

const MenuItem = ({ inverted, label, onClick, open, path, active, t }) => (
    <BaseLink
        to={path}
        inverted={inverted ? 1 : 0}
        onClick={onClick}
        open={open}
        active={active ? 1 : 0}
    >
        {t(label)}
    </BaseLink>
);

MenuItem.propTypes = {
    inverted: PropTypes.bool,
    label: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    open: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

export default translate()(MenuItem);
