import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Media from 'react-media';
import compose from 'recompose/compose';
import { Location } from '@reach/router';
import { translate } from 'react-polyglot';
import { Link } from 'gatsby';
import withLocale from '../i18n/withLocale';

import { fadeIn } from '../animations';
import MaxWidthContainer from '../MaxWidthContainer';
import Affix from '../Affix';
import Menu from './Menu';
import Logo from '../Logo';
import Brand from '../Brand';

const getPages = lang => [
    {
        label: 'menu.home',
        path: `/${lang}`,
        exact: true,
    },
    {
        label: 'menu.values',
        path: `/${lang}/values`,
    },
    {
        label: 'menu.showcase',
        path: `/${lang}/showcase`,
    },
    {
        label: 'menu.jobs',
        path: `/${lang}/jobs`,
    },
    {
        title: 'title.blog',
        label: 'menu.blog',
        path: `/${lang}/blog`,
        match: /^\/((fr|en)\/)?blog/,
    },
];

const OverContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 5rem;

    @media (max-width: 600px) {
        height: 3.125rem;
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    background-color: ${({ inverted }) => (inverted ? 'transparent' : 'white')};
    box-sizing: border-box;
    height: 5rem;
    margin: 0;
    position: ${({ fixed }) => (fixed ? 'fixed' : 'relative')};
    box-shadow: ${({ fixed }) => (fixed ? '0 1px 0 0 #d4d4ce' : 'unset')};
    z-index: 1000;
    top: 0;
    left: ${({ fixed }) => (fixed ? 0 : 'unset')};
    right: ${({ fixed }) => (fixed ? 0 : 'unset')};
    transition: all 0.3s ease-in-out;
    animation: ${({ fixed }) =>
        fixed ? css`0.3s ${fadeIn} ease-out` : 'unset'};
    width: 100%;

    @media (max-width: 600px) {
        padding: 0 1.1rem;
        height: 3.125rem;
    }
`;

const InnerContainer = styled(MaxWidthContainer)`
    align-items: center;
    flex-direction: row;
    @media (max-width: 600px) {
        display: none;
    }
`;

const CurrentSection = styled.h2`
    color: ${({ theme }) => theme.text};
    font-family: 'Open Sans', sans-serif;
    font-size: 0.75rem;
    font-weight: bold;
    margin: 0;
    padding: 0 0 0 0.6875rem;
    text-transform: uppercase;
`;

const StyledBrand = styled(Brand)`
    margin-left: 1rem;
    margin-right: auto;
    @media (max-width: 880px) {
        display: none;
    }
`;

const getCurrentSection = pages => pages.find(p => p.active);

const isPageActive = currentPath => ({ path, match, exact }) => {
    if (match) {
        return !!currentPath.match(match);
    }

    if (exact) {
        return currentPath === path;
    }

    return currentPath.startsWith(path);
};

class AppBar extends Component {
    static propTypes = {
        inverted: PropTypes.bool,
        location: PropTypes.object.isRequired,
        t: PropTypes.func.isRequired,
        locale: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        const isCurrentPage = isPageActive(this.props.location.pathname);

        const pages = getPages(props.locale).map(page => ({
            ...page,
            active: isCurrentPage(page),
        }));

        this.state = {
            menuOpen: false,
            currentSection: getCurrentSection(pages),
            pages,
        };
    }

    componentWillReceiveProps(nextProps) {
        const isCurrentPage = isPageActive(nextProps.location.pathname);

        const pages = getPages(nextProps.locale).map(page => ({
            ...page,
            active: isCurrentPage(page),
        }));

        this.setState({
            currentSection: getCurrentSection(pages),
            pages,
        });
    }

    handleMenuClick = () => {
        this.setState(({ menuOpen }) => ({ menuOpen: !menuOpen }));
    };

    render() {
        const { inverted, t } = this.props;
        const { currentSection, menuOpen, pages } = this.state;

        return (
            <OverContainer className="banner">
                <Affix>
                    {({ affix }) => (
                        <Media query="(min-width: 600px)">
                            {matches =>
                                matches ? (
                                    <Container
                                        inverted={inverted && !affix}
                                        fixed={affix}
                                    >
                                        <InnerContainer>
                                            <Link
                                                aria-label={t('menu.home')}
                                                to="/"
                                            >
                                                <Logo
                                                    inverted={
                                                        inverted && !affix
                                                    }
                                                />
                                                <StyledBrand
                                                    role="presentation"
                                                    inverted={
                                                        inverted && !affix
                                                    }
                                                />
                                            </Link>
                                            <Menu
                                                pages={pages}
                                                open
                                                inverted={inverted && !affix}
                                            />
                                        </InnerContainer>
                                    </Container>
                                ) : (
                                    <Container
                                        inverted={inverted && !affix}
                                        fixed={affix}
                                    >
                                        <Logo
                                            inverted={inverted && !affix}
                                            onClick={this.handleMenuClick}
                                        />
                                        <CurrentSection
                                            onClick={this.handleMenuClick}
                                        >
                                            {currentSection &&
                                            currentSection.label !== 'menu.home'
                                                ? t(
                                                      currentSection.title ||
                                                          currentSection.label,
                                                  )
                                                : affix && t('menu.menu')}
                                        </CurrentSection>
                                        <Menu
                                            pages={pages}
                                            open={menuOpen}
                                            onClose={this.handleMenuClick}
                                        />
                                    </Container>
                                )
                            }
                        </Media>
                    )}
                </Affix>
            </OverContainer>
        );
    }
}

const AppBarWithLocation = props => (
    <Location>
        {({ location }) => <AppBar {...props} location={location} />}
    </Location>
);

export default compose(withLocale, translate())(AppBarWithLocation);
