import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-media';
import Modal from 'react-modal';
import { MdClose as CloseIcon } from 'react-icons/md';
import { translate } from 'react-polyglot';

import { fadeIn, fadeOut } from '../animations';
import LanguageButton from './LanguageButton';
import MenuItem from './MenuItem';
import Logo from '../Logo';

const MenuLogo = styled(Logo)`
    padding-top: 0.3125rem;
`;

const Container = styled.div`
    display: flex;
    margin-left: auto;
    align-items: baseline;
    height: 5rem;

    @media (max-width: 600px) {
        height: 100%;
        align-items: stretch;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
    }
`;

const ContactUs = styled.a`
    height: 1.375rem;
    color: ${({ theme }) => theme.white};
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 0.75rem;
    transition: color 0.3s ease-in-out;
    white-space: nowrap;
    &:hover {
        color: ${({ theme }) => theme.white};
    }

    @media (max-width: 600px) {
        font-size: 1.1rem;
    }
`;

const ContactUsContainer = styled.div`
    align-content: center;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    background-color: ${({ inverted, theme }) =>
        inverted ? 'transparent' : theme.blue};
    border-color: ${({ inverted, theme }) =>
        inverted ? theme.white : 'transparent'};
    border-style: solid;
    border-radius: 2rem;
    border-width: 0;
    padding: 0.5rem 1rem;
    &:hover {
        background: ${({ theme }) => theme.blueDark};
        border-color: ${({ theme }) => theme.blueDark};
    }

    @media (min-width: 600px) {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    @media (max-width: 600px) {
        font-size: 1.1rem;
        display: block;
        text-align: center;
        margin: 1rem 2rem;
        padding: 1rem 1.5rem;
    }
`;

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: auto;
    margin-top: 0.5625rem;
`;

const ItemsContainer = styled.div`
    display: flex;
    flex-direction: row;
    @media (min-width: 601px) {
        > * {
            margin: 0 1.25rem;
        }
    }
    @media (max-width: 600px) {
        align-items: center;
        flex-direction: column;
        flex-grow: 2;
        justify-content: space-around;
        > * {
            margin: 1.25rem 0;
        }
    }
`;

const CloseButton = styled(CloseIcon)`
    color: ${({ theme }) => theme.grayDarker};
    font-size: 2.5rem;
`;

const LanguageButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
        font-size: 1.1rem;
    }
`;

const LanguageSeparator = styled.div`
    color: ${({ inverted, theme }) => (inverted ? theme.white : theme.text)};
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 0 0.625rem;
`;

const StyledModal = styled(Modal)`
    background: ${({ theme }) => theme.whiteDark};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    border: none;
    border-radius: 0;
    padding: 0.25rem 1.1rem 1rem 1.1rem;
    box-sizing: border-box;
    &.ReactModal__Content--after-open {
        animation: 0.3s ${fadeIn} ease-in;
    }
    &.ReactModal__Content--before-close {
        animation: 0.3s ${fadeOut} ease-in;
    }
`;

const Menu = ({ inverted, pages, onClose, open, t }) => (
    <Container>
        <Media
            defaultMatches={false}
            query="(max-width: 600px)"
            render={() => (
                <Buttons>
                    <MenuLogo onClick={onClose} />
                    <CloseButton onClick={onClose} />
                </Buttons>
            )}
        />
        <ItemsContainer>
            {pages.map(page => (
                <MenuItem
                    key={page.label}
                    label={page.label}
                    path={page.path}
                    active={page.active}
                    inverted={inverted}
                    onClick={onClose}
                    open={open}
                />
            ))}
            <LanguageButtons>
                <LanguageButton open={open} inverted={inverted} code="en" />
                <LanguageSeparator inverted={inverted}>/</LanguageSeparator>
                <LanguageButton open={open} inverted={inverted} code="fr" />
            </LanguageButtons>
            <ContactUsContainer inverted={inverted}>
                <ContactUs
                    inverted={inverted}
                    href="mailto:contact@marmelab.com"
                >
                    {t('aboutus.contact_us')}
                </ContactUs>
            </ContactUsContainer>
        </ItemsContainer>
    </Container>
);

Menu.propTypes = {
    inverted: PropTypes.bool,
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
        }),
    ),
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

const ModalMenu = ({ open, ...props }) => (
    <Media query="(min-width: 600px)">
        {matches =>
            matches ? (
                <Menu {...props} open />
            ) : (
                <StyledModal
                    closeTimeoutMS={300}
                    contentLabel="Menu"
                    isOpen={open}
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1001,
                        },
                    }}
                >
                    <Menu {...props} open={open} />
                </StyledModal>
            )
        }
    </Media>
);

ModalMenu.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default translate()(ModalMenu);
