import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';

import defaultTheme, { GlobalStyle } from './theme';
import favicon from '../../static/favicon.ico';

if (typeof fetch === 'undefined' || typeof Header === 'undefined') {
    require('whatwg-fetch');
}

if (typeof Array.from === 'undefined') {
    require('core-js/es6');
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

class TemplateWrapper extends Component {
    render() {
        const { children } = this.props;

        return (
            <ThemeProvider theme={defaultTheme}>
                <GlobalStyle />
                <Container>
                    <Helmet>
                        <link rel="icon" href={favicon} type="image/x-icon" />
                        <link
                            rel="stylesheet"
                            media="print"
                            href="/print.css"
                        ></link>
                        <link
                            rel="alternate"
                            type="application/atom+xml"
                            title="Marmelab Blog"
                            href="https://marmelab.com/atom.xml"
                        />
                        <meta
                            name="google-site-verification"
                            content="HCeXnHBSyOjSf85AoGfZIS6EUw0Xz94iw-3eaz20Ps0"
                        />

                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:site" content="@marmelab" />
                        <meta name="twitter:creator" content="@marmelab" />

                        <script
                            async
                            defer
                            data-website-id="4e27e12d-187c-4d13-b8e5-947a9f1976e2"
                            src="https://analytics.marmelab.com/umami.js"
                        ></script>
                    </Helmet>
                    {children}
                </Container>
            </ThemeProvider>
        );
    }
}

TemplateWrapper.propTypes = {
    children: PropTypes.node,
};

export default TemplateWrapper;
