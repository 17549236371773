import styled from 'styled-components';

const MaxWidthContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    max-width: ${({ width = 1280 }) => width}px;
    padding: 0 1rem;

    @media (max-width: 600px) {
        width: auto;
        margin: 0;
        padding: 0;
        width: 100%;
    }
`;

export default MaxWidthContainer;
