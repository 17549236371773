import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import brand from '../../static/images/brand.png';
import brandWhite from '../../static/images/brand-white.png';

const StyledImg = styled.img`
    height: 2rem;
`;

const Brand = ({ inverted, ...rest }) => (
    <StyledImg src={inverted ? brandWhite : brand} {...rest} />
);

Brand.propTypes = {
    inverted: PropTypes.bool,
};

export default Brand;
