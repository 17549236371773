import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'gatsby-image';

const Container = styled.div`
    position: relative;
`;

const SubContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const BackgroundImage = styled(Image)`
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
    & > img {
        object-fit: cover !important; // or whatever
        object-position: 50% 50% !important; // or whatever
        font-family: 'object-fit: cover !important; object-position: 50% 50% !important;'; // needed for IE9+ polyfill
    }
`;

const WithBackgroundImage = ({
    children,
    image,
    imageStyle,
    alt,
    ...otherProps
}) => {
    let imageComponent = null;
    let containerStyle = {
        ...otherProps.style,
    };
    if (!image) {
        // if not image provided, put a big red image to that you can locate which is the problem on the page
        imageComponent = (
            <img
                alt={alt}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAABn0lEQVR42u3TAQ0AAAjDMO5fNCAABaTNLCxdtQGXGAQMAgYBg4BBwCBgEDAIGAQMAhgEDAIGAYOAQcAgYBAwCBgEDGIQMAgYBAwCBgGDgEHAIGAQMAhgEDAIGAQMAgYBg4BBwCBgEDAIYBAwCBgEDAIGAYOAQcAgYBDAIGAQMAgYBAwCBgGDgEHAIGAQwCBgEDAIGAQMAgYBg4BBwCCAQcAgYBAwCBgEDAIGAYOAQcAggEHAIGAQMAgYBAwCBgGDgEHAIAYBg4BBwCBgEDAIGAQMAgYBgwAGAYOAQcAgYBAwCBgEDAIGAYMYBAwCBgGDgEHAIGAQMAgYBAwCGAQMAgYBg4BBwCBgEDAIGAQMAhgEDAIGAYOAQcAgYBAwCBgEMAgYBAwCBgGDgEHAIGAQMAgYBDAIGAQMAgYBg4BBwCBgEDAIYBAwCBgEDAIGAYOAQcAgYBAwCGAQMAgYBAwCBgGDgEHAIGAQMIhBwCBgEDAIGAQMAgYBg4BBwCCAQcAgYBAwCBgEDAIGAYOAQcAgBgGDgEHAIGAQMAgYBAwCHw2n4Y9IKdr/IQAAAABJRU5ErkJggg=="
            />
        );
    } else if (image.childImageSharp) {
        if (image.childImageSharp.fluid) {
            imageComponent = (
                <BackgroundImage
                    fluid={image.childImageSharp.fluid}
                    style={imageStyle}
                    alt={alt}
                />
            );
        }
        if (image.childImageSharp.fixed) {
            imageComponent = (
                <BackgroundImage
                    fixed={image.childImageSharp.fixed}
                    style={imageStyle}
                    alt={alt}
                />
            );
        }
    } else {
        containerStyle.background = `url(${image.publicURL ||
            image}) 50% 50% / cover no-repeat`;
    }
    return (
        <Container {...otherProps} style={containerStyle}>
            <SubContainer>{imageComponent}</SubContainer>
            {children}
        </Container>
    );
};

WithBackgroundImage.propTypes = {
    children: PropTypes.node.isRequired,
    image: PropTypes.object,
    imageStyle: PropTypes.object,
    alt: PropTypes.string,
};

export default WithBackgroundImage;
