import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Location } from '@reach/router';
import compose from 'recompose/compose';
import { translate } from 'react-polyglot';

import { setLocale } from '../i18n';
import withLocale from '../i18n/withLocale';

const Link = styled.a`
    color: ${({ inverted, theme }) => (inverted ? theme.white : theme.text)};
    cursor: pointer;
    background: none;
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: ${({ selected }) => (selected ? 600 : 'normal')};
    text-transform: uppercase;
    font-size: 0.8rem;
    text-decoration: none;

    @media (max-width: 600px) {
        transition: font-weight 0.3s ease-out, opacity 0.3s ease-out 0.2s;
        opacity: ${({ open }) => (open ? 1 : 0)};
        margin: 0;
        font-size: 1.1rem;
        display: block;
        text-align: center;
    }
`;

class LanguageButton extends Component {
    static propTypes = {
        code: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired,
        inverted: PropTypes.bool,
        open: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        t: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = { selected: props.locale === props.code };
    }

    getLink = () => {
        const { code, locale, location } = this.props;

        const localeFromPath = location.pathname.substr(1, 2);

        if (['en', 'fr'].indexOf(localeFromPath) > -1) {
            const pathWithoutLocale = location.pathname.substr(3);

            if (locale !== code) {
                return `/${code}${pathWithoutLocale}`;
            }
        }
        return null;
    };

    handleClick = () => {
        const { code } = this.props;
        const link = this.getLink();

        if (link) {
            setLocale(code);
            navigate(link);
        }
    };

    render() {
        const { code, inverted, open, t } = this.props;
        const { selected } = this.state;

        const isServerSideRendering = typeof window === 'undefined';
        let navigationProps = null;
        if (isServerSideRendering) {
            navigationProps = {
                href: this.getLink(),
            };
        } else {
            navigationProps = {
                onClick: this.handleClick,
            };
        }

        return (
            <Link
                aria-label={t('setLocale', { code })}
                selected={selected}
                inverted={inverted}
                open={open}
                {...navigationProps}
            >
                {code}
            </Link>
        );
    }
}

const LanguageButtonWithLocation = props => (
    <Location>
        {({ location }) => <LanguageButton {...props} location={location} />}
    </Location>
);

export default compose(withLocale, translate())(LanguageButtonWithLocation);
