import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import logo from '../../static/images/logo-small.png';
import logoWhite from '../../static/images/logo-small-white.png';

const StyledImage = styled.img`
    height: 2rem;
    @media (max-width: 600px) {
        height: 1.5rem;
    }
`;

const LogoComponent = ({ inverted, ...rest }) => (
    <StyledImage
        src={inverted ? logoWhite : logo}
        role="presentation"
        {...rest}
    />
);

LogoComponent.propTypes = {
    inverted: PropTypes.bool,
};

export default LogoComponent;
