import styled from 'styled-components';

import WithBackgroundImage from './Image/WithBackgroundImage';

export const genericBlogImage = '/images/blog/generic_blog.jpg';

export default styled(WithBackgroundImage)`
    position: relative;
    height: auto;
    min-height: 24.69rem;
    @media (min-width: 1200px) {
        width: 100%;
    }
    @media (max-width: 600px) {
        min-height: 10.63rem;
    }

    > div:not(:first-child):not(.banner) {
        position: relative;
        z-index: 1;
    }
`;
