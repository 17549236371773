import styled from 'styled-components';

import PrimaryButton from './PrimaryButton';

export default styled(PrimaryButton)`
    background-color: transparent;
    border: solid 2px ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.white};

    &:hover {
        background-color: ${({ theme }) => theme.blue};
        color: ${({ theme }) => theme.white};
    }
`;
